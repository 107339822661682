import React from 'react';

// const HomePage = () => (
//   <Layout home>
//     <SEO />
//     <Home />
//   </Layout>
// );

const HomePage = () => (
 <div>Nothing to see...</div>
);

export default HomePage;
